import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "The 7th heading",
  "description": "In case you run out of headings",
  "lead": "You may think there are only 6 of them. And you are right, but there's a little trick for you, if you run out of headings.",
  "dateCreated": "2021-07-30T00:00:00.000Z",
  "tags": "HTML",
  "slug": "the-7th-heading",
  "postUrl": "https://uselessdivs.com/blog/the-7th-heading/",
  "ogImg": "https://uselessdivs.com/img/the-7th-heading/og-img.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`As we all may know, we have 6 levels of native HTML header tags. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h1>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h2>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h3>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h4>`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h5>`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<h6>`}</code>{`.`}</p>
    <p>{`All you need to know about these 6:`}</p>
    <ul>
      <li parentName="ul">{`You should only have one `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<h1>`}</code>{` per page.`}</li>
      <li parentName="ul">{`Make sure they are in ascending order.`}</li>
      <li parentName="ul">{`Avoid skipping heading levels.`}</li>
    </ul>
    <p>{`There is one more thing: `}<em parentName="p">{`Don't use heading level for styling`}</em></p>
    <p>{`I can hardly imagine a situation where this isn't enough for me, but if we have to work with an extremely complex document, there's a solution for adding more heading levels.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Heading lvl 1`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Heading lvl 2`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Heading lvl 3`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h4`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Heading lvl 4`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h5`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Heading lvl 5`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h5`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h6`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Heading lvl 6`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h6`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`heading`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-level`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`7`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  This is the 7th heading.
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}</code></pre></div>
    <p>{`As you can see in the above example I used 2 WAI-ARIA attributes: `}<strong parentName="p">{`role`}</strong>{` and `}<strong parentName="p">{`aria-level`}</strong>{`.`}</p>
    <p>{`The `}<em parentName="p">{`role="heading" attribute`}</em>{` indicates to screen readers and other assistive technologies that this element should be treated like other html heading elements.`}<br />{`
The `}<em parentName="p">{`aria-level`}</em>{` attribute tells the heading level.`}</p>
    <p>{`You can use these attributes on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<div>`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<span>`}</code>{` elements.`}</p>
    <p className="takeaway">
  You should use native HTML tags for the first 6 levels of headings in the correct order.<br />
  If you need a 7th heading level, please consider to restructure you document.<br />
  If this isn't possible, use the mentioned attributes.
    </p>
    <p>{`You can read more about these attributes here:`}</p>
    <ul>
      <li parentName="ul">
        <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/heading_role" rel="noreferrer" target="_blank">ARIA: heading role - Accessibility | MDN</a>
      </li>
      <li parentName="ul">
        <a href="https://www.digitala11y.com/aria-level-properties/" rel="noreferrer" target="_blank">WAI-ARIA: aria-level (Property) • Digital A11Y</a>
      </li>
      <li parentName="ul">
        <a href="https://www.w3.org/TR/2017/REC-wai-aria-1.1-20171214/#aria-level" rel="noreferrer" target="_blank">Accessible Rich Internet Applications (WAI-ARIA) 1.1</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      